import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { IAppGuard } from './_nav';
import { AppSessionService } from './app-session.service';
import { AppSignalrService } from './app-signalr.service';
import { AppSettingsService } from './data-services/app-settings.service';
import { DataService } from './data-services/data.service';
import { IAccount, IAccountMember } from './interfaces/Account';
import { IAppTimeZone } from './interfaces/AppTimeZone';
import { IAuth0Login, ILogin, ILoginReturn, IPasswordValidator, IPasswordValidatorResponse } from './interfaces/Login';
import { InternalRoles } from './interfaces/roles-enum';
import { ISession } from './interfaces/Session';

@Injectable()
export class AppAuthService {
  public isLoggedIn = false;
  public internalRole: InternalRoles = InternalRoles.None;
  public AccountMember: IAccountMember;
  public Account: IAccount;
  public email: string;
  public online = false;
  public AuthExpiration: string;
  public AuthToken: string;
  public ActivateMercury = false;
  public MercuryTimeZoneName = 'America/Chicago';
  redirectUrl: string;
  private _baseUrl = AppSettingsService.Settings.baseUrl;
  public guardsSource = new BehaviorSubject<IAppGuard[]>(null);
  public guardsDefinitions = this.guardsSource.asObservable();
  public guards !: IAppGuard[];
  public session: ISession;

  private refreshTokenTimeout;
  private get currentRefreshToken(): string { return sessionStorage.getItem('refreshToken'); }
  private set currentRefreshToken(value: string) { sessionStorage.setItem('refreshToken', value); }

  constructor(private _http: HttpClient, private _dataService: DataService, private _sessionService: AppSessionService,
  private signalRService: AppSignalrService) {
    if (this._sessionService.currentSession) {
      this.session = this._sessionService.getSession();
      this.isLoggedIn = this.session.isLoggedIn;
      this.AccountMember = this.session.accountMember;
      this.Account = this.session.account;
      this.AuthExpiration = this.session.expirationDate;
      this.AuthToken = this.session.token;
      this.internalRole = this.session.internalRole;
      this.email = this.session.email;
      this.ActivateMercury = this.session.activateMercury;
      this.guards = this.session.guards;
    }
  }

  setToken(token: string, expiration: string) {
    this.session = this.getSession();
    this.session.token = token;
    this.session.expirationDate = expiration;
    this.setSession(this.session);

    this.AuthExpiration = expiration;
    this.AuthToken = token;
  }

  setClientId(clientId: string): void {
    sessionStorage.setItem('MML-SESSION-CLIENTID', clientId);
  }

  getClientId(): string {
    return sessionStorage.getItem('MML-SESSION-CLIENTID');
  }

  getAuth0Token(): string{
    return sessionStorage.getItem("auth0IdToken");
  }

  getOrganizationId(): string {
    return localStorage.getItem('auth0OrgId');
  }

  getGuards(): void {
    this._dataService.getAppGuardDefinitions().subscribe(data => {
      this.guards = data;
      this.guardsSource.next(this.guards);
      this._sessionService.setGuards(this.guards);
    });
  }
  // *
  emailCanCreateAccountMember(email: string, password: string, clientId: string) {
    const user: ILogin = {
      UserName: email,
      Email: email,
      Password: password
    };
    const api = '/AccountMembers/CreatePassword/';
    return this._http.post<IAccountMember>(`${this._baseUrl}${api}`, user, {
      headers: {
        ClientId: clientId
      }
    });
  }

  validatePassword(email: string, password: string, clientId: string): Observable<IPasswordValidatorResponse> {
    const passwordValidator: IPasswordValidator = {
      UserName: email,
      Password: password
    };
    const api = '/AccountMembers/Password/Validate';
    return this._http.post<IPasswordValidatorResponse>(`${this._baseUrl}${api}`, passwordValidator, {
      headers: {
        ClientId: clientId
      }
    });
  }

  // *
  loginService(entity: ILogin, clientId: string) {
    const api = '/Account/Login/';
    return this._http.post<ILoginReturn>(`${this._baseUrl}${api}`, entity, {
      headers: {
        ClientId: clientId
      },
      withCredentials: true
    }).pipe(map((authenticationModel) => {
      this.currentRefreshToken = authenticationModel.RefreshToken;
      return authenticationModel;
    }));
  }

  // *
  forgotPasswordService(email: string, clientId: string) {
    const api = '/AccountMembers/RecoverPassword';
    const params = new HttpParams().set('email', email);

    return this._http.post<any>(`${this._baseUrl}${api}`, clientId, {
      params: params,
      headers: {
        clientId: clientId
      }
    });

  }

  // *
  getAccountByEmail(email: string) {
    const api = '/AccountMembers/';
    const params = new HttpParams().set('email', email);
    return this._http.get<IAccountMember>(`${this._baseUrl}${api}`, { params: params });
  }

  // *
  getAccountMemberByEncryptedId(encryptedId: string, clientId: string) {
    const api = '/AccountMembers/';
    return this._http.get<IAccountMember>(`${this._baseUrl}${api}${encryptedId}`, {
      headers: {
        ClientId: clientId
      }
    });
  }

  // *
  public getAccount(id: number): Observable<IAccount> {
    const api = '/Accounts/';
    return this._http.get<IAccount>(`${this._baseUrl}${api}${id}`);
  }

  // *
  getAppTimeZones() {
    const api = '/TimeZones/';
    return this._http.get<IAppTimeZone[]>(`${this._baseUrl}${api}`);
  }
  // *
  updateAccountMember(id: number, entity: IAccountMember) {
    const api = '/AccountMembers/';
    return this._http.put<IAccountMember>(`${this._baseUrl}${api}${id}`, entity);
  }
  // *
  addAccountMember(entity: IAccountMember) {
    const api = '/AccountMembers/';
    return this._http.post<IAccountMember>(`${this._baseUrl}${api}`, entity);
  }

  private handleError(err: HttpErrorResponse) {
    console.log(err.error);
    return Observable.throwError(err.error);
  }

  login(email: string, internalRole: InternalRoles, accountMember: IAccountMember) {
    this.email = email;
    this.internalRole = internalRole;
    this.isLoggedIn = true;
    this.AccountMember = accountMember;
    this.ActivateMercury = false;
    this._sessionService.setClientId(this.getClientId());
    this.session = this.getSession();
    this.session.isLoggedIn = true;
    this.session.accountId = this.AccountMember.AccountId;
    this.session.accountMemberId = this.AccountMember.Id;
    this.session.accountMember = accountMember;
    this.session.internalRole = internalRole;
    this.setSession(this.session);
    this.startRefreshTokenTimer();
  }

  getSession() {
    return this._sessionService.getSession();
  }

  setSession(session: ISession): void {
    this._sessionService.setSession(session);
  }

  setSessionAccount(account: IAccount): void {
    this._sessionService.setAccount(account);
  }

  setSessionAccountMember(accountMember: IAccountMember): void {
    this._sessionService.setAccountMember(accountMember);
  }

  setSessionEmail(email: string): void {
    this._sessionService.setEmail(email);
  }

  setIsLoggedIn(isLoggedIn: boolean): void {
    this._sessionService.setIsLoggedIn(isLoggedIn);
  }

  setGuards(guards: IAppGuard[]): void {
    this._sessionService.setGuards(guards);
  }

  loginAdmin(email: string) {
    this.email = email;
    this.internalRole = InternalRoles.Admin;
    this.isLoggedIn = true;
    this.AccountMember = null;
    this.ActivateMercury = true;
    this._sessionService.setEmail(email);
    this._sessionService.setInternalRole(InternalRoles.Admin);
    this._sessionService.setIsLoggedIn(true);
    this._sessionService.setAccountMember(null);
    this._sessionService.setActivateMercury(true);
    this._sessionService.setClientId(this.getClientId());
    this.session = this._sessionService.getSession();
  }

  logout(): void {
    this.stopRefreshTokenTimer();
    this.revokeRefreshToken().subscribe();
    this.AuthToken = '';
    this.AuthExpiration = '';
    this.email = '';
    this.isLoggedIn = false;
    this.internalRole = InternalRoles.None;
    this.redirectUrl = '';
    this.AccountMember = null;
    this.ActivateMercury = false;
    this.Account = null;
    this._sessionService.clear();
    this.signalRService.CloseSignalR();
  }

  refreshToken() {
    this.stopRefreshTokenTimer();

    if (!this.isLoggedIn) { return EMPTY; }

    return this._http.post<any>(`${this._baseUrl}/auth/refresh-token`, { RefreshToken: this.currentRefreshToken })
      .pipe(map((authenticationModel) => {
        this.currentRefreshToken = authenticationModel.RefreshToken;
        this.setToken(authenticationModel.Token, authenticationModel.Expiration);
        this.startRefreshTokenTimer();
        return authenticationModel;
      }));
  }

  loginAuth0(entity: IAuth0Login, clientId: string) {
    const api = '/Account/LoginWithAuth0/';
    return this._http.post<ILoginReturn>(`${this._baseUrl}${api}`, entity, {
      headers: {
        ClientId: clientId
      }
    }).pipe(map((authenticationModel) => {
      this.currentRefreshToken = authenticationModel.RefreshToken;
      return authenticationModel;
    }));
  }

  private revokeRefreshToken() {
    if (!this.isLoggedIn) { return EMPTY; }
    if (!this.currentRefreshToken) { return EMPTY; }

    return this._http.post<any>(`${this._baseUrl}/auth/revoke-token`, { RefreshToken: this.currentRefreshToken })
      .pipe(map(() => { this.currentRefreshToken = null; }));
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.AuthToken.split('.')[1]));

    // set a timeout to refresh the token 60 seconds before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
